//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HomeView",
  data() {
    return {
     list:[]
    };
  },
  mounted() {
  
    window.document.title = "全员营销";
    this.getList()
  },
  methods: {
    getList(){
      this.list=[]
       this.axios
        .get(
          "/" + this.$ajaxPrefix.consumer + 
          "/v1/wx/cp/crm/customer/getMemberBindHrLog",
        )
        .then((res) => {
          console.log(res.data);
           if (res && res.data && res.data.data && res.data.data.length > 0) {
              this.list=res.data.data;
          }
         
        });
    },
    goPage(){
      this.$router.push('/staffsale/mygrey')
    } ,  
     hideMobile(str){
      var reg = /^(\d{3})\d{4}(\d{4})$/;  
       return str.replace(reg, "$1****$2");   
  },
  formatDate(str){
     return this.$moment(str).format('YYYY-MM-DD');  
  }
  },
};
